import React from 'react'
import { Link } from 'gatsby'

const LightButton = ({ title, style, to }) => {
  return (
    <Link to={to} style={style} className="button light small">
      {title}
    </Link>
  )
}

export default LightButton
