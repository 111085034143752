import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql, Link } from "gatsby"
import PageHeader from "../components/header/page-header"

import Service1 from "../images/services/1.svg"
import Service2 from "../images/services/2.svg"
import Service3 from "../images/services/3.svg"
import Service4 from "../images/services/4.svg"
import Service5 from "../images/services/5.svg"
import Service6 from "../images/services/6.svg"

import { ArrowForward } from "react-ionicons"
import { StaticImage } from "gatsby-plugin-image"

const Services = () => {
  const data = useStaticQuery(graphql`
    query Services {
      bgImage: file(
        relativePath: { eq: "services/header_images/it-services-bg.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout bgColor="#f4f4f9">
      <SEO
        title="IT Services | Designed to suite company specific solutions"
        description="We provide adaptable and advanced systems integration solutions and engineering services that resolve existing IT defects as well as anticipate and resolve future business challenges. Our services include system development, cloud infrastructure, and consultation designed to suit company specific requirements. Our aim is to develop solutions that speed up and optimize your business productivity."
        metaImage={data.bgImage.childImageSharp.fluid.src}
      />
      <PageHeader
        className="services-header"
        title="IT SERVICES"
        thisPage="IT Services"
        subtitle="We provide adaptable and advanced systems integration solutions and engineering services that resolve existing IT defects as well as anticipate and resolve future business challenges. Our services include system development, cloud infrastructure, and consultation designed to suit company specific requirements.
Our aim is to develop solutions that speed up and optimize your business productivity."
        background={data.bgImage.childImageSharp.fluid}
      />
      <div className="service-grid col-md-10 mx-auto pb-5">
        <div className="row">
          <Service6mdBox
            title="Business Concept Development"
            description="Enhancing your business with crucial tech-related directives that tackle challenges and maximise efficiency."
            image={Service5}
            buttonTitle="Get Started "
            to="/services/business-concept-development"
          />
          <Service6mdBox
            title="Web Development"
            description="Building your website, the platform that showcases your business product and brand identity"
            image={Service6}
            buttonTitle="Find Out More"
            to="/services/web-development"
          />
          {ServiceData.map((item, index) => {
            return (
              <Service6mdBox
                key={index}
                title={item.title}
                image={item.image}
                description={item.description}
                buttonTitle={item.buttonTitle}
                to={item.to}
              />
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

const ServiceData = [
  {
    title: "Managed Cloud Services",
    description:
      "Efficient and affordable access to cloud computing and storage ",
    image: Service2,
    buttonTitle: "Defend Your Data",
    to: "/services/managed-cloud-service",
  },
  // {
  //   title: "Managed IT Services",
  //   description: "It maintenance and safety to a trusted third-party.",
  //   image: Service1,
  //   buttonTitle: "Stay Up And Running",
  //   to: "/services/managed-it-service",
  // },
  // {
  //   title: "Cyber Security",
  //   description: "We make defending your IT resources our number one priority",
  //   image: Service3,
  //   buttonTitle: "Protect Your Data",
  //   to: "/services/cyber-security",
  // },

  {
    title: "IT Consultancy",
    description:
      "An expert to guide you on the latest industry trends, essential transformations, and targeted IT strategies",
    image: Service4,
    buttonTitle: "See All Consultancy",
    to: "/services/it-consultancy",
  },
]

const Service3mdBox = ({ title, description, image, buttonTitle, to }) => {
  return (
    <div className="col-md-3  mb-4">
      <div className="service-icon-box">
        <div>
          <h4 className="box-title">{title}</h4>
          <div className="box-detail">
            <p>{description}</p>
          </div>
        </div>
        <div className="box-icon">
          <img src={image} alt="icon-service" />
        </div>

        <div className="box-readmore">
          <Link className="button no-bg" to={to}>
            {buttonTitle}
            <ArrowForward className="icon-next" fontSize="16px" />
          </Link>
        </div>
      </div>
    </div>
  )
}
const Service6mdBox = ({ title, description, image, buttonTitle, to }) => {
  return (
    <div className="col-md-6 mb-4">
      <div className="service-icon-box">
        <h3 className="box-title">{title}</h3>
        <div className="box-detail">
          <p>{description}</p>
        </div>
        <div className="box-icon">
          <img src={image} alt="icon-service" />
        </div>
        <div className="box-readmore">
          <Link className="button no-bg" to={to}>
            {buttonTitle}
            <ArrowForward className="icon-next" fontSize="16px" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Services
